import { useState, useRef } from 'react'
import type { FormEvent } from 'react'
import { FormControlLabel, Checkbox, SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import capitalize from 'lodash/capitalize'
import type { LoginPaths, SAMLConfiguration } from 'login/types'
import { textFieldStyles } from '../styles/styleProps'
import SubmitButton from './SubmitButton'
import validatePresence from '../lib/validatePresence'
import NavigationLink from './NavigationLink'
import AuthenticityToken from './AuthenticityToken'
import StyledTextField from './StyledTextField'
import type { Props as TextFieldProps } from './StyledTextField'
import MultiSSOButtons from './MultiSSO/MultiSSOButtons'

type Args = {
  multiSamlEnabled: boolean
}

const useFormControlLabelStyles = makeStyles({
  label: ({ multiSamlEnabled }: Args) => ({
    color: multiSamlEnabled
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(255, 255, 255, 0.75)',
    fontSize: '14px',
    lineHeight: '24px',
  }),
  root: {
    color: 'white',
  },
})

const useCheckboxStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
  icon: ({ multiSamlEnabled }: Args) =>
    multiSamlEnabled
      ? {
          '.Mui-focusVisible &': {
            outline:
              '1px auto Highlight; outline: 1px auto -webkit-focus-ring-color',
          },
        }
      : {},
})

const getDefaultUserName = () =>
  window.document.cookie
    .split(';')
    .map((pair) => pair.split('='))
    .reduce((accum, [key, value]) => ({ ...accum, [key.trim()]: value }), {
      username: undefined,
    }).username || ''

const separationStyles = {
  height: 8,
  width: '200%',
}
const submitWaitTimeout = 1000

export type Props = {
  hideLocalLogin?: boolean
  multiSamlEnabled?: boolean
  onSubmit: () => void
  ossProvider: string
  paths: LoginPaths
  resourceName: string
  samlConfigurations?: SAMLConfiguration[]
  userField: string
}

const LoginForm = ({
  hideLocalLogin,
  multiSamlEnabled = false,
  onSubmit,
  ossProvider,
  paths,
  resourceName,
  samlConfigurations,
  userField,
}: Props): JSX.Element => {
  const checkboxClasses = useCheckboxStyles({ multiSamlEnabled })
  const formControlLabelClasses = useFormControlLabelStyles({
    multiSamlEnabled,
  })
  const [errors, setErrors] = useState<
    Partial<Record<'email' | 'password', string | null>>
  >({})
  const [emailFieldValue, setEmailFieldValue] = useState(getDefaultUserName())
  const [passwordFieldValue, setPasswordFieldValue] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)

  const addError = (field: 'email' | 'password', message: string | null) => {
    if (errors[field] !== message) {
      setErrors({ ...errors, [field]: message })
    }
  }

  const formHasErrors = () => {
    const emailError = validatePresence(userField, emailFieldValue)
    const passwordError = validatePresence('password', passwordFieldValue)

    setErrors({
      email: emailError,
      password: passwordError,
    })

    return emailError || passwordError
  }

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      window.document.cookie = `username=${emailFieldValue}; path='/'`

      // Add a small timeout to allow the spinner animation to start
      // on Safari before navigating away
      if (!isSubmitted) {
        onSubmit()
        setSubmitted(true)
        setTimeout(() => {
          formRef.current?.submit()
        }, submitWaitTimeout)
        event.preventDefault()
      }
    }
  }

  const handleEmailChange: TextFieldProps['onChange'] = (event) => {
    const { value } = event.target

    if (value) {
      setEmailFieldValue(value)
      const error = validatePresence('email', value)

      addError('email', error)
    }
  }

  const handlePasswordChange: TextFieldProps['onChange'] = (event) => {
    const { value } = event.target

    if (value) {
      setPasswordFieldValue(value)
      const error = validatePresence('password', value)

      addError('password', error)
    }
  }

  const userFieldName = () => {
    if (ossProvider === 'ldap') {
      return userField
    }

    return `${resourceName}[${userField}]`
  }

  const passwordFieldName = () => {
    if (ossProvider === 'ldap') {
      return 'password'
    }

    return `${resourceName}[password]`
  }

  const showForgotPassword = () => {
    if (ossProvider === 'ldap') {
      return null
    }

    return (
      <NavigationLink
        href={paths.newPassword}
        multiSamlEnabled={multiSamlEnabled}
      >
        Forgot password?
      </NavigationLink>
    )
  }

  const form = (
    <form
      ref={formRef}
      action={paths.createSession}
      aria-label="Login form"
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <input autoFocus name="oss_provider" type="hidden" value={ossProvider} />
      <StyledTextField
        className={textFieldStyles.className}
        defaultValue={getDefaultUserName()}
        FormHelperTextProps={{
          error: Boolean(errors.email),
        }}
        helperText={errors.email}
        InputLabelProps={{ id: 'email-label' }}
        inputProps={{
          'data-testid': 'email',
          'aria-labelledby': 'email-label',
        }}
        label={capitalize(userField)}
        multiSamlEnabled={multiSamlEnabled}
        name={userFieldName()}
        onBlur={handleEmailChange}
        onChange={handleEmailChange}
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <div style={separationStyles} />
      <StyledTextField
        className={textFieldStyles.className}
        FormHelperTextProps={{
          error: Boolean(errors.password),
        }}
        helperText={errors.password}
        InputLabelProps={{ id: 'password-label' }}
        inputProps={{
          'data-testid': 'password',
          'aria-labelledby': 'password-label',
        }}
        label="Password"
        multiSamlEnabled={multiSamlEnabled}
        name={passwordFieldName()}
        onBlur={handlePasswordChange}
        onChange={handlePasswordChange}
        type="password"
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <SubmitButton label="SIGN IN" multiSamlEnabled={multiSamlEnabled} />
      <FormControlLabel
        classes={{
          label: formControlLabelClasses.label,
          root: formControlLabelClasses.root,
        }}
        control={
          <Checkbox
            checkedIcon={
              <SvgIcon className={checkboxClasses.icon}>
                <path
                  d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5
                     1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                />
              </SvgIcon>
            }
            classes={{
              root: checkboxClasses.root,
            }}
            color="default"
            icon={
              <SvgIcon className={checkboxClasses.icon}>
                <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
              </SvgIcon>
            }
            name="user[remember_me]"
            value={1}
          />
        }
        label="Remember me"
      />
      {showForgotPassword()}
    </form>
  )

  return (
    <>
      {(!multiSamlEnabled || !hideLocalLogin) && form}
      {multiSamlEnabled && (
        <MultiSSOButtons
          hideLocalLogin={hideLocalLogin}
          samlConfigurations={samlConfigurations ?? []}
        />
      )}
    </>
  )
}

export default LoginForm
