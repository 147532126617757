import merge from 'lodash/merge'

export function getCSRFToken(): string | undefined {
  const header = document.querySelector<HTMLMetaElement>(
    'meta[name="csrf-token"]',
  )

  return header?.content
}

export const getCSRFHeader = (): { [key: string]: string | undefined } => ({
  'X-CSRF-Token': getCSRFToken(),
})

type Headers = Record<string, string>

export const getCSRFFetchOptions = (
  fetchOptions?: Omit<RequestInit, 'headers'> & { headers?: Headers },
): Omit<RequestInit, 'headers'> & { headers: Headers } =>
  merge({}, fetchOptions, {
    credentials: 'same-origin',
    headers: getCSRFHeader(),
  })
