import { Component } from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import OverlayProgressDialog from './OverlayProgressDialog'

type Props = {
  dialogText: string
  multiSamlEnabled: boolean
  serverFeedback: string | null | undefined
}

type State = {
  requesting: boolean
  snackbarText: string | null | undefined
}

export default class NotificationsCenter extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      requesting: false,
      snackbarText: this.props.serverFeedback || '',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.setState({
      snackbarText: nextProps.serverFeedback,
    })
  }

  handleClearSnackbarText = (): void => {
    this.setState({
      snackbarText: '',
    })
  }

  openProgressDialog = (): void => {
    this.setState({
      requesting: true,
    })
  }

  closeProgressDialog = (): void => {
    this.setState({
      requesting: false,
    })
  }

  render(): JSX.Element {
    return (
      <>
        <OverlayProgressDialog
          dialogText={this.props.dialogText}
          multiSamlEnabled={this.props.multiSamlEnabled}
          open={this.state.requesting}
        />
        <Snackbar
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={this.handleClearSnackbarText}
            >
              <CloseIcon />
            </IconButton>
          }
          autoHideDuration={16000}
          message={<span>{this.state.snackbarText}</span>}
          onClose={this.handleClearSnackbarText}
          open={!!this.state.snackbarText}
        />
      </>
    )
  }
}
