import { baseTheme } from 'common/themes/materialTheme'
import merge from 'lodash/merge'

export const loginTheme = merge({}, baseTheme, {
  palette: {
    error: {
      main: '#F88077',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#212121',
        boxShadow: `
          0px 1px 12px 0px rgba(0, 0, 0, 0.16),
          0px 4px 8px 0px rgba(0, 0, 0, 0.08),
          0px 2px 4px 0px rgba(0, 0, 0, 0.08)
        `,
      },
    },
  },
})
