import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

const buttonStyles = makeStyles({
  root: {
    padding: '16px 24px 16px 16px',
    width: '100%',
    maxWidth: 294,
    textTransform: 'none',
    borderRadius: '4px',
    background: 'white',
    marginBottom: '12px',
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  focusVisible: {
    outline: 'revert',
  },
})

const styles = makeStyles({
  image: { width: '24px', height: '24px', paddingRight: '12px' },
  text: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '19px',
    textAlign: 'start',
  },
})

type MultiSSOButtonProps = {
  'data-testid': string
  iconURL: string
  text: string
  redirectURL: string
}

const MultiSSOButton = ({
  'data-testid': dataTestid,
  iconURL,
  text,
  redirectURL,
}: MultiSSOButtonProps): JSX.Element => {
  const buttonClasses = buttonStyles()
  const classes = styles()

  const onClick = () => {
    window.location.href = redirectURL
  }

  return (
    <Button
      classes={buttonClasses}
      data-testid={dataTestid}
      disableRipple
      onClick={onClick}
      type="button"
      variant="contained"
    >
      <img alt="" aria-hidden="true" className={classes.image} src={iconURL} />
      <Typography className={classes.text} component="span">
        {text}
      </Typography>
    </Button>
  )
}

export default MultiSSOButton
