import { instructions } from '../styles/specificStyles'
import { instructions as newInstructions } from '../styles/multiSamlStyles'

type Props = {
  children: React.ReactNode
  className?: string
  multiSamlEnabled?: boolean
}

const InstructionsText = ({
  children,
  multiSamlEnabled = false,
  ...rest
}: Props): JSX.Element => (
  <p style={multiSamlEnabled ? newInstructions : instructions} {...rest}>
    {children}
  </p>
)

export default InstructionsText
