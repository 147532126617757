// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CircularProgress, Dialog } from '@material-ui/core'
import { mediumWhite } from '../styles/additionalColors'

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px',
    color: mediumWhite,
    fontSize: '15px',
    position: 'relative' as const,
    width: 190,
    padding: 20,
    overflow: 'hidden',
  },
  text: {
    marginLeft: '22px',
  },
}

type Props = {
  dialogText: string
  multiSamlEnabled?: boolean
  open: boolean
}

const OverlayProgressDialog = ({
  dialogText,
  multiSamlEnabled = false,
  open,
}: Props) => (
  <Dialog open={open}>
    <div
      style={{
        backgroundColor: multiSamlEnabled ? '#757575' : '#809dbd',
        ...styles.body,
      }}
    >
      <CircularProgress
        size={33}
        style={multiSamlEnabled ? { color: '#fff' } : {}}
        thickness={3}
      />
      <div style={styles.text}>{dialogText}</div>
    </div>
  </Dialog>
)

export default OverlayProgressDialog
