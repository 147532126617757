// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { useState, useRef } from 'react'
import type { PasswordValidationProps } from 'login/types'
import AuthenticityToken from './AuthenticityToken'
import ConfirmationField from './ConfirmationField'
import HttpMethodInput from './HttpMethodInput'
import NavigationLink from './NavigationLink'
import PasswordTextField from './PasswordTextField'
import SubmitButton from './SubmitButton'

type Props = {
  multiSamlEnabled?: boolean
  onSubmit: () => void
  passwordValidationProps: PasswordValidationProps
  paths: any
  resetPasswordToken: string
  serverErrors: any
}

const EditPasswordForm = ({
  multiSamlEnabled = false,
  onSubmit,
  passwordValidationProps,
  paths,
  resetPasswordToken,
  serverErrors,
}: Props) => {
  const [errors, setErrors] = useState(serverErrors || {})
  const passwordFieldRef = useRef(null)
  const confirmationFieldRef = useRef(null)

  /* @ts-expect-error auto-src: noflow */
  const addError = (field, message) => {
    if (errors[field] !== message) {
      errors[field] = message
      setErrors(errors)
    }
  }

  const formHasErrors = () => {
    /* @ts-expect-error auto-src: noflow */
    const confirmationPasswordError = confirmationFieldRef.current.errors(true)
    /* @ts-expect-error auto-src: noflow */
    const isNewPasswordValid = passwordFieldRef.current.validate()

    setErrors({
      password_confirmation: confirmationPasswordError,
    })

    return !isNewPasswordValid || confirmationPasswordError
  }

  /* @ts-expect-error auto-src: noflow */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSubmit()
    }
  }

  /* @ts-expect-error auto-src: noflow */
  const handleConfirmationValidation = (error) => {
    addError('password_confirmation', error)
  }

  /* @ts-expect-error auto-src: noflow */
  const handlePasswordChange = (event) => {
    /* @ts-expect-error auto-src: noflow */
    confirmationFieldRef.current.handleOriginalFieldChange(event)
  }

  return (
    <form
      action={paths.update}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <HttpMethodInput />
      <input
        name="user[reset_password_token]"
        type="hidden"
        value={resetPasswordToken}
      />
      <PasswordTextField
        ref={passwordFieldRef}
        initialError={errors.password}
        label="New password"
        multiSamlEnabled={multiSamlEnabled}
        name="user[password]"
        onChange={handlePasswordChange}
        {...passwordValidationProps}
      />
      <ConfirmationField
        ref={confirmationFieldRef}
        FormHelperTextProps={{
          error: Boolean(errors.password_confirmation),
        }}
        helperText={errors.password_confirmation}
        humanIdentifier="password confirmation"
        InputLabelProps={{ id: 'password-confirmation' }}
        inputProps={{
          'aria-labelledby': 'password-confirmation',
        }}
        label="Password confirmation"
        multiSamlEnabled={multiSamlEnabled}
        name="user[password_confirmation]"
        onValidate={handleConfirmationValidation}
        originalFieldPlural="passwords"
        type="password"
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <SubmitButton
        label="CHANGE YOUR PASSWORD"
        multiSamlEnabled={multiSamlEnabled}
      />
      <NavigationLink
        href={paths.newSession}
        multiSamlEnabled={multiSamlEnabled}
      >
        Sign In
      </NavigationLink>
    </form>
  )
}

export default EditPasswordForm
