// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { useState, useRef } from 'react'
import validatePresence from '../lib/validatePresence'
import StyledTextField from './StyledTextField'
import AuthenticityToken from './AuthenticityToken'
import InstructionsText from './InstructionsText'
import NavigationLink from './NavigationLink'
import SubmitButton from './SubmitButton'

type Props = {
  multiSamlEnabled?: boolean
  onSubmit: () => void
  paths: any
}

const ResetPassword = ({
  multiSamlEnabled = false,
  onSubmit,
  paths,
}: Props) => {
  const [errors, setErrors] = useState({})
  const [emailFieldValue, setEmailFieldValue] = useState('')
  const formRef = useRef(null)

  /* @ts-expect-error auto-src: strict-conversion */
  const addError = (field, message) => {
    /* @ts-expect-error auto-src: strict-conversion */
    if (errors[field] !== message) {
      /* @ts-expect-error auto-src: strict-conversion */
      errors[field] = message
      setErrors(errors)
    }
  }

  const formHasErrors = () => {
    const emailError = validatePresence('email', emailFieldValue)

    setErrors({
      email: emailError,
    })

    return emailError
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSubmit()
      /* @ts-expect-error auto-src: strict-conversion */
      formRef.current.submit()
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleEmailChange = (event) => {
    const eventTargetValue = event.target.value

    setEmailFieldValue(eventTargetValue)

    if (eventTargetValue) {
      const error = validatePresence('email', eventTargetValue)

      addError('email', error)
    }
  }

  return (
    <form
      ref={formRef}
      action={paths.createPassword}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <InstructionsText multiSamlEnabled={multiSamlEnabled}>
        Enter your email to reset your password
      </InstructionsText>
      <StyledTextField
        FormHelperTextProps={{
          /* @ts-expect-error auto-src: non-strict-conversion */
          error: Boolean(errors.email),
        }}
        /* @ts-expect-error auto-src: non-strict-conversion */
        helperText={errors.email}
        InputLabelProps={{ id: 'email-label' }}
        inputProps={{
          'data-testid': 'email',
          'aria-labelledby': 'email-label',
        }}
        label="Email"
        multiSamlEnabled={multiSamlEnabled}
        name="user[email]"
        onBlur={handleEmailChange}
        onChange={handleEmailChange}
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <SubmitButton
        label="RESET PASSWORD"
        multiSamlEnabled={multiSamlEnabled}
      />
      <NavigationLink
        href={paths.newSession}
        multiSamlEnabled={multiSamlEnabled}
      >
        Sign in
      </NavigationLink>
    </form>
  )
}

export default ResetPassword
