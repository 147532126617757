import type { ThemeProviderProps } from '@material-ui/styles'
import { ThemeProvider } from '@material-ui/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { baseTheme } from 'common/themes/materialTheme'
import { loginTheme } from '../constants/theme'

type Props = {
  children: React.ReactNode
  multiSamlEnabled?: boolean
  themeProps?: ThemeProviderProps
}

const newLoginMuiTheme = createMuiTheme(loginTheme)

const legacyLoginMuiTheme = createMuiTheme(baseTheme)

export default ({
  children,
  multiSamlEnabled = false,
  themeProps,
}: Props): JSX.Element => {
  const muiTheme = multiSamlEnabled ? newLoginMuiTheme : legacyLoginMuiTheme

  return (
    <ThemeProvider {...themeProps} theme={muiTheme}>
      {children}
    </ThemeProvider>
  )
}
