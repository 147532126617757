import type { FormEvent } from 'react'
import InstructionsText from './InstructionsText'
import SubmitButton from './SubmitButton'
import ThemeProvider from './ThemeProvider'

type Props = {
  multiSamlEnabled: boolean
  paths: {
    newSession: string
  }
}

const SsoLogout = ({ multiSamlEnabled, paths }: Props): JSX.Element => {
  const handleClick = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    window.location.pathname = paths.newSession
  }

  return (
    <ThemeProvider multiSamlEnabled={multiSamlEnabled}>
      <InstructionsText multiSamlEnabled={multiSamlEnabled}>
        Signed out successfully.
      </InstructionsText>
      <form onSubmit={handleClick}>
        <SubmitButton label="SIGN IN" multiSamlEnabled={multiSamlEnabled} />
      </form>
    </ThemeProvider>
  )
}

export default SsoLogout
