import { useRef } from 'react'
import type { LoginPaths } from 'login/types'
import NotificationsCenter from './NotificationsCenter'
import UnlockForm from './UnlockForm'
import ThemeProvider from './ThemeProvider'

type Props = {
  email: string | undefined
  multiSamlEnabled: boolean
  paths: Pick<LoginPaths, 'newSession' | 'createUnlock'>
  serverFeedback: string | null | undefined
}

const LockedAccount = (props: Props): JSX.Element => {
  const notificationsRef = useRef<NotificationsCenter | null>(null)

  const handleSubmit = (): void => {
    notificationsRef.current?.openProgressDialog()
  }

  const { multiSamlEnabled, serverFeedback, ...rest } = props

  return (
    <ThemeProvider multiSamlEnabled={multiSamlEnabled}>
      <NotificationsCenter
        ref={(notifications) => {
          notificationsRef.current = notifications
        }}
        dialogText="Please wait..."
        multiSamlEnabled={multiSamlEnabled}
        serverFeedback={serverFeedback}
      />

      <UnlockForm
        {...rest}
        multiSamlEnabled={multiSamlEnabled}
        onSubmit={handleSubmit}
        paths={props.paths}
      />
    </ThemeProvider>
  )
}

export default LockedAccount
