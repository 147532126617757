import { deepOrange, orange, amber, lime } from '@material-ui/core/colors'

export const userDefinedWords = ['officespacesoftware', 'officespace'] as const

export const editableFieldNames = [
  'first_name',
  'last_name',
  'initials',
  'password',
  'current_password',
  'password_confirmation',
]

const PASSWORD_STRENGHTS = {
  PUNY: 'Puny',
  WEAK: 'Weak',
  MEDIOCRE: 'Mediocre',
  GOOD: 'Good',
  GREAT: 'Great',
} as const

export const passwordStrengthsList = [
  PASSWORD_STRENGHTS.PUNY,
  PASSWORD_STRENGHTS.WEAK,
  PASSWORD_STRENGHTS.MEDIOCRE,
  PASSWORD_STRENGHTS.GOOD,
  PASSWORD_STRENGHTS.GREAT,
] as const

export const passwordScoreColors = {
  [PASSWORD_STRENGHTS.PUNY]: deepOrange[200],
  [PASSWORD_STRENGHTS.WEAK]: orange[200],
  [PASSWORD_STRENGHTS.MEDIOCRE]: amber[500],
  [PASSWORD_STRENGHTS.GOOD]: lime[500],
  [PASSWORD_STRENGHTS.GREAT]: '#aeea00',
}

export const newPasswordScoreColors = {
  [PASSWORD_STRENGHTS.PUNY]: '#F88077',
  [PASSWORD_STRENGHTS.WEAK]: '#FFAF74',
  [PASSWORD_STRENGHTS.MEDIOCRE]: '#FBBC05',
  [PASSWORD_STRENGHTS.GOOD]: '#00D97E',
  [PASSWORD_STRENGHTS.GREAT]: '#00D97E',
}
