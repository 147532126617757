import type { ButtonProps } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

type Args = {
  multiSamlEnabled: boolean
}

const useStyles = makeStyles({
  root: ({ multiSamlEnabled }: Args) => ({
    color: 'white',
    backgroundColor: multiSamlEnabled ? '#116E91' : '#4a6c9e',
    marginBottom: multiSamlEnabled ? '38px' : '32px',
    marginTop: multiSamlEnabled ? '38px' : '36px',
    fontFamily: 'Open Sans',
    '&:hover': {
      backgroundColor: multiSamlEnabled
        ? 'rgba(17, 110, 145, 0.7)'
        : 'rgba(74, 108, 158, 0.7)',
    },
    // eslint-disable-next-line no-magic-numbers
    fontWeight: multiSamlEnabled ? 600 : 400,
  }),
  focusVisible: ({ multiSamlEnabled }) => ({
    outline: multiSamlEnabled ? 'revert' : 'undefined',
  }),
})

type Props = ButtonProps & {
  label: string
  multiSamlEnabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const SubmitButton = (props: Props): JSX.Element => {
  const { label, multiSamlEnabled = false, type = 'submit', ...rest } = props
  const classes = useStyles({ multiSamlEnabled })

  return (
    <Button classes={classes} fullWidth type={type} {...rest}>
      {label}
    </Button>
  )
}

export default SubmitButton
