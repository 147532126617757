import type { CSSProperties } from '@material-ui/styles'
import generalStyles from './generalStyles'
import { kashmirBlue, kashmirBlueHover } from './additionalColors'

export const textFieldStyles = {
  className: 'login-autofill shrink-ie-input-icons',
  floatingLabelStyle: generalStyles.floatingLabel,
  style: generalStyles.textField,
}
export const buttonStyles: CSSProperties = {
  backgroundColor: kashmirBlue,
  color: '#fff',
  '&:hover': {
    backgroundColor: kashmirBlueHover,
  },
}
