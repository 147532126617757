import { useState, useRef } from 'react'
import nameFromField from '../lib/nameFromField'
import validatePresence from '../lib/validatePresence'
import AuthenticityToken from './AuthenticityToken'
import ConfirmationField from './ConfirmationField'
import HttpMethodInput from './HttpMethodInput'
import InstructionsText from './InstructionsText'
import NavigationLink from './NavigationLink'
import PasswordTextField from './PasswordTextField'
import SubmitButton from './SubmitButton'
import StyledTextField from './StyledTextField'

export type Props = {
  firstName: string
  initials: string
  invitationToken: string
  lastName: string
  multiSamlEnabled: boolean
  onSubmit: () => void
  passwordValidationProps: {
    email: string
    firstName: string
    lastName: string
    minLength: number
    minScore: number
  }
  paths: {
    newSession: string
    update: string
  }
  serverErrors: Record<string, string>
}

const EditInvitationForm = ({
  firstName,
  initials,
  invitationToken,
  lastName,
  multiSamlEnabled = false,
  onSubmit,
  passwordValidationProps,
  paths,
  serverErrors,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<Record<string, string | null>>(
    serverErrors || {},
  )
  const [firstNameValue, setFirstNameValue] = useState(firstName)
  const [lastNameValue, setLastNameValue] = useState(lastName)
  const [initialsValue, setInitialsValue] = useState(initials)
  const confirmationFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)

  const addError = (field: string, message: string | null) => {
    if (errors[field] !== message) {
      setErrors({
        ...errors,
        [field]: message,
      })
    }
  }

  const formHasErrors = () => {
    const firstNameError = validatePresence('first name', firstNameValue)
    const lastNameError = validatePresence('last name', lastNameValue)
    const initialsError = validatePresence('initials', initialsValue)
    /* @ts-expect-error auto-src: strict-conversion */
    const confirmationError = confirmationFieldRef.current.errors(true)
    /* @ts-expect-error auto-src: strict-conversion */
    const isPasswordValid = passwordFieldRef.current.validate()

    setErrors({
      first_name: firstNameError,
      last_name: lastNameError,
      initials: initialsError,
      password_confirmation: confirmationError,
    })

    return (
      !isPasswordValid ||
      firstNameError ||
      lastNameError ||
      initialsError ||
      confirmationError
    )
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSubmit()
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleRequiredFieldChange = (event) => {
    const { value } = event.target

    if (value) {
      const name = nameFromField(event.target)

      if (name === 'first_name') {
        setFirstNameValue(value)
      }

      if (name === 'last_name') {
        setLastNameValue(value)
      }

      if (name === 'initials') {
        setInitialsValue(value)
      }

      const error = validatePresence(name, value)

      addError(name, error)
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleConfirmationValidation = (error) => {
    addError('password_confirmation', error)
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handlePasswordChange = (event) => {
    /* @ts-expect-error auto-src: strict-conversion */
    confirmationFieldRef.current.handleOriginalFieldChange(event)
  }

  return (
    <form
      action={paths.update}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <HttpMethodInput />
      <input
        name="user[invitation_token]"
        type="hidden"
        value={invitationToken}
      />
      <InstructionsText multiSamlEnabled={multiSamlEnabled}>
        Welcome to OfficeSpace!
      </InstructionsText>
      <StyledTextField
        defaultValue={firstName}
        FormHelperTextProps={{
          error: Boolean(errors.first_name),
        }}
        helperText={errors.first_name}
        InputLabelProps={{ id: 'first-name-label' }}
        inputProps={{
          'aria-labelledby': 'first-name-label',
        }}
        label="First name"
        multiSamlEnabled={multiSamlEnabled}
        name="user[first_name]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <StyledTextField
        defaultValue={lastName}
        FormHelperTextProps={{
          error: Boolean(errors.last_name),
        }}
        helperText={errors.last_name}
        InputLabelProps={{ id: 'last-name-label' }}
        inputProps={{
          'aria-labelledby': 'last-name-label',
        }}
        label="Last name"
        multiSamlEnabled={multiSamlEnabled}
        name="user[last_name]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <StyledTextField
        defaultValue={initials}
        FormHelperTextProps={{
          error: Boolean(errors.initials),
        }}
        helperText={errors.initials}
        InputLabelProps={{ id: 'initials-label' }}
        inputProps={{
          'aria-labelledby': 'initials-label',
        }}
        label="Initials"
        multiSamlEnabled={multiSamlEnabled}
        name="user[initials]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <PasswordTextField
        ref={passwordFieldRef}
        initialError={errors.password}
        multiSamlEnabled={multiSamlEnabled}
        name="user[password]"
        onChange={handlePasswordChange}
        {...passwordValidationProps}
      />
      <ConfirmationField
        ref={confirmationFieldRef}
        FormHelperTextProps={{
          error: Boolean(errors.password_confirmation),
        }}
        helperText={errors.password_confirmation}
        humanIdentifier="password confirmation"
        InputLabelProps={{ id: 'password-confirmation-label' }}
        inputProps={{
          'aria-labelledby': 'password-confirmation-label',
        }}
        label="Password confirmation"
        multiSamlEnabled={multiSamlEnabled}
        name="user[password_confirmation]"
        onValidate={handleConfirmationValidation}
        originalFieldPlural="passwords"
        type="password"
        {...(multiSamlEnabled && { helperColor: '#F88077' })}
      />
      <SubmitButton label="REGISTER" multiSamlEnabled={multiSamlEnabled} />
      <NavigationLink
        href={paths.newSession}
        multiSamlEnabled={multiSamlEnabled}
      >
        Sign In
      </NavigationLink>
    </form>
  )
}

export default EditInvitationForm
