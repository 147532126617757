import { type FormEvent, useEffect } from 'react'
import type { LoginPaths } from 'login/types'
import AuthenticityToken from './AuthenticityToken'
import InstructionsText from './InstructionsText'
import NavigationLink from './NavigationLink'
import SubmitButton from './SubmitButton'

export type Props = {
  email: string | undefined
  multiSamlEnabled: boolean
  onSubmit: (event: FormEvent) => void
  paths: Pick<LoginPaths, 'newSession' | 'createUnlock'>
}

const UnlockForm = (props: Props): JSX.Element => {
  useEffect(() => {
    if (!props.email) {
      window.location.pathname = props.paths.newSession
    }
  }, [props.email, props.paths.newSession])

  const handleFormSubmit = (event: FormEvent) => {
    props.onSubmit(event)
  }

  return (
    <form
      action={props.paths.createUnlock}
      method="post"
      onSubmit={handleFormSubmit}
    >
      <InstructionsText multiSamlEnabled={props.multiSamlEnabled}>
        Your account has been locked. Please check your email for unlock
        instructions.
      </InstructionsText>
      <AuthenticityToken />
      <input name="user[email]" type="hidden" value={props.email || ''} />
      <SubmitButton
        label="RESEND INSTRUCTIONS"
        multiSamlEnabled={props.multiSamlEnabled}
      />
      <NavigationLink
        href={props.paths.newSession}
        multiSamlEnabled={props.multiSamlEnabled}
      >
        Sign In
      </NavigationLink>
    </form>
  )
}

export default UnlockForm
