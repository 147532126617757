// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types'
import { Component, Ref, type ComponentType } from 'react'
import NotificationsCenter from './NotificationsCenter'
import { editableFieldNames } from './lib'
import ThemeProvider from './ThemeProvider'

type BaseFormProps = {
  multiSamlEnabled: boolean
  onSubmit: () => void
  serverErrors: Record<string, string>
}

type Children<T extends object> = ComponentType<T & BaseFormProps>

type Props<T extends object> = T & {
  children: Children<T>
  dialogText?: string
  errors: Record<string, string>
  multiSamlEnabled: boolean
  serverFeedback: string | undefined
}

type State = {
  activeFeedback: string
  formErrors: Record<string, string>
}

export default class ResourceFormPage<T extends object> extends Component<
  Props<T>,
  State
> {
  notificationsRef: NotificationsCenter | null

  constructor(props: Props<T>) {
    super(props)
    const errors = { ...this.props.errors }
    // The errors that correspond to a field are presented as errors on the field.
    // The ones that do not correspond to any field are presented as notifications.
    const activeFeedback = Object.keys(errors || {})
      .filter((key) => editableFieldNames.indexOf(key) === -1)
      .map((key) => {
        const error = errors[key]

        delete errors[key]

        return error
      })
      .concat((this.props.serverFeedback && [this.props.serverFeedback]) || [])
      .join(' ')

    this.notificationsRef = null

    this.state = {
      activeFeedback,
      formErrors: errors,
    }
  }

  handleSubmit = (): void => {
    this.notificationsRef?.openProgressDialog()
  }

  render(): JSX.Element {
    const { children, dialogText, errors, serverFeedback, ...rest } = this.props
    const childProps = rest as T & BaseFormProps
    const ChildrenProp = children as Children<T>

    return (
      <ThemeProvider multiSamlEnabled={this.props.multiSamlEnabled}>
        <NotificationsCenter
          ref={(notifications) => {
            this.notificationsRef = notifications
          }}
          dialogText={this.props.dialogText || 'Please wait...'}
          multiSamlEnabled={this.props.multiSamlEnabled}
          serverFeedback={this.state.activeFeedback}
        />
        <ChildrenProp
          {...childProps}
          onSubmit={this.handleSubmit}
          serverErrors={this.state.formErrors}
        />
      </ThemeProvider>
    )
  }
}
