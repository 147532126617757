// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { TextField } from '@material-ui/core'
import type { TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

type Args = {
  showError: boolean
}

const useStyles = makeStyles({
  root: {
    width: 290,
    marginTop: 18,
  },
})
const useInputStyles = makeStyles({
  root: {
    fontFamily: 'Open Sans',
  },
  formControl: {
    color: 'white',
  },
  underline: {
    borderBottomColor: 'white',
    '&:hover:not(.Mui-error)': {
      borderBottomColor: 'white',
      '&&:before': {
        borderBottomColor: 'white',
      },
      '&&:after': {
        borderBottomColor: 'white',
      },
    },
    '&:before': {
      borderBottomColor: 'rgba(255,255,255,0.3)',
    },
    '&:after': {
      borderBottomColor: 'rgba(255,255,255,0.3)',
    },
  },
})
const useInputLabelStyles = makeStyles({
  root: {
    color: 'rgba(255,255,255,0.5)',
  },
  animated: ({ showError }: Args) => ({
    color: `rgba(255,255,255,0.6)${showError ? '' : ' !important'}`,
  }),
})
const useformHelperTextStyles = makeStyles({
  root: {
    color: 'rgb(255, 171, 145) !important',
  },
})

const useColoredFormHelperTextStyles = makeStyles({
  root: {
    color: ({ helperColor }: { helperColor: string | undefined }) =>
      `${helperColor} !important`,
  },
})

export type Props = {
  FormHelperTextProps: any
  helperColor?: string
  multiSamlEnabled?: boolean
  textFieldRef?: TextFieldProps['ref']
} & TextFieldProps

const StyledTextField = ({
  FormHelperTextProps,
  helperColor,
  multiSamlEnabled = false,
  textFieldRef,
  ...props
}: Props) => {
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const inputLabelClasses = useInputLabelStyles({
    showError: multiSamlEnabled && !!FormHelperTextProps?.error,
  })
  const defaultFormHelperTextClasses = useformHelperTextStyles()
  const coloredFormHelperTextClasses = useColoredFormHelperTextStyles({
    helperColor,
  })
  const formHelperTextClasses = helperColor
    ? coloredFormHelperTextClasses
    : defaultFormHelperTextClasses

  return (
    <TextField
      ref={textFieldRef}
      classes={classes}
      {...(multiSamlEnabled ? { error: !!FormHelperTextProps?.error } : {})}
      {...props}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: formHelperTextClasses,
      }}
      InputLabelProps={{
        classes: inputLabelClasses,
        ...props.InputLabelProps,
      }}
      InputProps={{
        classes: inputClasses,
        ...props.InputProps,
      }}
    />
  )
}

export default StyledTextField
