import generalStyles from '../styles/generalStyles'
import { navigationLink } from '../styles/specificStyles'
import { link } from '../styles/multiSamlStyles'

type Props = {
  children: React.ReactNode
  href: string
  multiSamlEnabled?: boolean
}

const NavigationLink = ({
  children,
  href,
  multiSamlEnabled = false,
  ...rest
}: Props): JSX.Element => {
  const styles = {
    ...(multiSamlEnabled ? link : generalStyles.link),
    ...navigationLink,
    fontSize: '14px',
  }

  return (
    <a href={href} style={styles} {...rest}>
      {children}
    </a>
  )
}

export default NavigationLink
