// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { useRef } from 'react'
import type { SAMLConfiguration } from 'login/types'
import LoginForm from './LoginForm'
import NotificationsCenter from './NotificationsCenter'
import ThemeProvider from './ThemeProvider'

type Props = {
  hideLocalLogin?: boolean
  multiSamlEnabled: boolean
  ossProvider: string
  paths: any
  resourceName: string
  samlConfigurations?: SAMLConfiguration[]
  serverFeedback?: string
  userField: string
}

const Login = ({
  hideLocalLogin,
  multiSamlEnabled,
  ossProvider,
  paths,
  resourceName,
  samlConfigurations,
  serverFeedback,
  userField,
}: Props) => {
  const notificationsRef = useRef(null)

  const handleSubmit = () => {
    /* @ts-expect-error auto-src: strict-conversion */
    notificationsRef.current.openProgressDialog()
  }

  return (
    <ThemeProvider multiSamlEnabled={multiSamlEnabled}>
      <NotificationsCenter
        ref={notificationsRef}
        dialogText="Authenticating..."
        multiSamlEnabled={multiSamlEnabled}
        serverFeedback={serverFeedback}
      />

      <LoginForm
        hideLocalLogin={hideLocalLogin}
        multiSamlEnabled={multiSamlEnabled}
        onSubmit={handleSubmit}
        ossProvider={ossProvider}
        paths={paths}
        resourceName={resourceName}
        samlConfigurations={samlConfigurations}
        userField={userField}
      />
    </ThemeProvider>
  )
}

export default Login
