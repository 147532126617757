import type { CSSProperties } from 'react'

export const instructions: CSSProperties = {
  color: '#fff',
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: '42px',
  textAlign: 'center',
}

export const link: CSSProperties = {
  color: 'rgba(255, 255, 255, 0.7)',
}
