import ResourceFormPage from './ResourceFormPage'
import EditInvitationForm, {
  type Props as FormProps,
} from './EditInvitationForm'

export type Props = Pick<
  FormProps,
  | 'firstName'
  | 'initials'
  | 'invitationToken'
  | 'lastName'
  | 'multiSamlEnabled'
  | 'passwordValidationProps'
  | 'paths'
> & {
  errors: Record<string, string>
  serverFeedback: string | undefined
}

const EditInvitation = (props: Props): JSX.Element => (
  <ResourceFormPage<Props> {...props}>{EditInvitationForm}</ResourceFormPage>
)

export default EditInvitation
